import { ClickAwayListener, Popper } from "@mui/material";
import React from "react";
import Box from "@mui/material/Box";
import MenuIcon from "@mui/icons-material/Menu";

function Submenu() {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;
  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <span>
          <MenuIcon onClick={handleClick} style={{ color: "black" }} />
          <Popper
            id={id}
            open={open}
            anchorEl={anchorEl}
            sx={{
              zIndex: "5",
            }}
          >
            <Box
              sx={{
                p: 1,
                bgcolor: "background.paper",
                marginTop: "12%",
                width: "211px",
                zIndex: "5",
                border: "1px solid",
                background: "#FFFFFF",
                borderRadius: "0px 0px 5.85916px 5.85916px",
              }}
            >
              {/* <MenuItems value="Home" onClick={() => {}}>
                Home
              </MenuItems>
              <MenuItems value="aboutus" onClick={() => {}}>
                About us
              </MenuItems>
              <MenuItems value="Partners" onClick={() => {}}>
                Partners
              </MenuItems>
              <MenuItems value="contactus" onClick={() => {}}>
                Contact us
              </MenuItems>
              <Container>
                <ContainerHeading>Download our App Now</ContainerHeading>
                <ContainerImage>
                  <img
                    src={Google}
                    style={{
                      width: "100%",
                      borderRadius: "2.11403px",
                      cursor: "pointer",
                    }}
                    alt="icon"
                  />
                </ContainerImage>
              </Container> */}
            </Box>
          </Popper>
        </span>
      </ClickAwayListener>
    </>
  );
}

export default Submenu;
