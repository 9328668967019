export default () => {
  if (window.innerWidth <= 600) {
    return true;
  } else {
    return false;
  }
};


export const tablet = () => {
  if (window.innerWidth <= 1000) {
    return true;
  } else {
    return false;
  }
};