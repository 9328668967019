import React from "react";
import isMobile from "../../assets/isMobile";

function HowitsEco() {
  return (
    <div
      style={{
        // width: "1150px",
        borderRadius: "12px",
        backgroundColor: "#F9F6FF",
        textAlign: "center",
        padding: "3% 0%",
        margin: isMobile() ? "0% 7%" : "0% 15%",
      }}
    >
      <h4
        style={{
          fontFamily: "Poppins",
          fontSize: isMobile() ? "24px" : "32px",
          fontWeight: "500",
          lineHeight: isMobile() ? "36px" : "48px",
          letterSpacing: "0em",
          textAlign: "center",
          color: "#5B4B86",
          margin:0,
          // marginBottom: "0",
        }}
      >
        How
        <span
          style={{
            fontFamily: "Poppins",
            fontSize: isMobile() ? "24px" : "32px",
            fontWeight: "700",
            lineHeight: isMobile() ? "36px" : "48px",
            letterSpacing: "0em",
            textAlign: "center",
            color: "#5B4B86",
            margin: "0% 1.14%",
          }}
        >
          Unios
        </span>
        is building a pharmaceutical ecosystem?
      </h4>
      <p
        style={{
          fontFamily: "Poppins",
          fontSize: isMobile() ? "14px" : "18px",
          fontWeight: "400",
          lineHeight: isMobile() ? "21px" : "27px",
          letterSpacing: "0em",
          textAlign: "center",
        }}
      >
        “86% of the medicines are dispensed via pharmacies in India. Unios is
        empowering the pharmacies by re-engineering the complete supply chain
        with an aim that no prescription shall bounce in India”
      </p>
    </div>
  );
}

export default HowitsEco;
