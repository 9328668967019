import styled from "styled-components";
import isMobile, { tablet } from "../../../assets/isMobile";

export const Container = styled.div`
  display: flex;
  padding-left: ${isMobile() ? "3%" : "4%"};
  padding-top: ${isMobile() ? "6%" : "6px"};
`;

export const Content = styled.div`
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 50 !important;
  font-size: ${isMobile() ? "24px" : tablet() ? "30px" : "42px"};
  line-height: 63px;
  display: flex;
  align-items: center;
  color: #5b4b86;
  text-align: left;
  // width: 90%;
  @media (max-width: 1000px) {
    line-height: 120%;
    margin-top: 20%;
  }
  @media (max-width: 768px) {
    text-align: center;
    margin-bottom: 11px;
    line-height: 120%;
    margin-top: 0%;
    font-size: 25px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;
  }
`;

export const SubContent = styled.div`
  top: 424px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  // margin-bottom: 40px;
  @media (max-width: 768px) {
    text-align: center;
    margin-bottom: 11px;
    width: 86%;
    margin-left: 7%;
    color: #545454;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
  }
`;
