import { Container, Grid } from "@mui/material";
import "./index.css";
import isMobile, { tablet } from "../../assets/isMobile";

export default function GuestFooter() {
  return (
    <>
      <Container
        maxWidth={false}
        style={{
          backgroundColor: "#422E75",
          padding: "0% 15%",
          marginTop: "auto",
          marginBottom: "0px",
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            lg={7}
            style={{
              padding: "3.2% 0%",
              textAlign: tablet() ? "center" : "",
            }}
          >
            <img src="../Images/logo1.png" />
            <p
              style={{
                width: tablet() ? (isMobile() ? "100%" : "50%") : "343px",
                height: "86px",
                top: "3983px",
                left: "108px",
                fontFamily: "Poppins",
                fontSize: isMobile() ? "16px" : "18px",
                fontWeight: "400",
                lineHeight: isMobile() ? "24px" : "27px",
                letterSpacing: "0em",
                textAlign: !isMobile()
                  ? tablet()
                    ? "cemter"
                    : "left"
                  : "center",
                color: "#FFF",
                marginLeft: tablet() ? (isMobile() ? "" : "25%") : "",
              }}
            >
              Our commitment to excellence and customer satisfaction has earned
              us a reputation for reliability
            </p>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            lg={3}
            style={{ paddingTop: !tablet() ? "2%" : "" }}
          >
            <p
              style={{
                fontFamily: "Poppins",
                fontSize: "20px",
                fontWeight: "600",
                lineHeight: "30px",
                letterSpacing: "0.06em",
                color: "white",
                textAlign: tablet() ? "center" : "left",
              }}
            >
              Address
            </p>
            <p
              style={{
                width: tablet() ? "100%" : "196px",
                height: "86px",
                top: "3974px",
                left: "851px",
                fontFamily: "Poppins",
                fontSize: "18px",
                fontWeight: "300",
                lineHeight: "27px",
                letterSpacing: "0em",
                textAlign: tablet() ? "center" : "left",
                color: "#FFF",
                width: isMobile() ? "70%" : "196px",
                marginLeft: isMobile() ? "15%" : tablet() ? "33%" : "",
              }}
            >
              315, 3rd Floor, SAS Towers, Medicity, Sector 38, Gurugram, Haryana
              122001
            </p>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            lg={2}
            style={{ paddingTop: isMobile() ? "10.1%" : "2.1%" }}
          >
            {" "}
            <p
              style={{
                fontFamily: "Poppins",
                fontSize: "20px",
                fontWeight: "600",
                lineHeight: "30px",
                letterSpacing: "0.06em",
                color: "white",
                textAlign: tablet() ? "center" : "left",
              }}
            >
              Contact
            </p>
            <p
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "24px",
                letterSpacing: "0.06em",
                textAlign: tablet() ? "center" : "left",
                color: "#FFF",
              }}
            >
              9671480888 ,+91124-4014675
            </p>
            <p
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "11.2px",
                letterSpacing: "0.06em",
                textAlign: tablet() ? "center" : "left",
                color: "#FFF",
                marginBottom: tablet() ? "7%" : "",
              }}
            >
              care@unios.in
            </p>
            <div
              style={{
                display: "flex",
                gap: "20px",
                // marginLeft: tablet() ? "36%" : "2%",
                width: "100%",
                justifyContent: tablet() ? "center" : "left",
                marginBottom: "20px",
              }}
            >
              <img
                src="../Images/icon1.png"
                style={{ width: "11px", height: "22px" }}
              />
              <img
                src="../Images/icon2.png"
                style={{ width: "22px", height: "22px" }}
              />
              <img
                src="../Images/icon3.png"
                style={{ width: "22px", height: "22px" }}
              />
            </div>
          </Grid>
        </Grid>
      </Container>
      <div
        style={{
          width: "100%",
          height: "49px",
          backgroundColor: "#342260",
          color: "#FFF",
          textAlign: "center",
          fontFamily: "Poppins",
          fontSize: "16px",
          fontWeight: "400",
          lineHeight: "24px",
          letterSpacing: "0.05em",
          paddingTop: "15px",
        }}
      >
        © 2023 UNIOS. All rights reserved
      </div>
    </>
  );
}
