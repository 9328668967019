import ReactDOM from 'react-dom/client';
import Approuter from './router/Approuter';

export default function App() {
  return (
    <>
      <Approuter />
    </>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
