import styled from "styled-components";
import isMobile, { tablet } from "../../../assets/isMobile";

export const ContainerTopics1 = styled.div`
  width: ${isMobile() ? "" : "660px"};
  height: ${isMobile() ? "" : "111px"};
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 400;
  font-size: ${isMobile() ? "24px" : "36px"};
  line-height: ${isMobile() ? "" : "55px"};
  text-align: ${isMobile() ? "" : "left"};
  padding-bottom: ${isMobile() ? "20px" : ""};
`;

export const Copyrights1 = styled.div`
  font-family: "Libre Baskerville";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #1b1b1f;
  mix-blend-mode: normal;
  opacity: 0.75;
  text-align: left;
`;

export const StyleButton = styled.button`
  min-width: max-content;
  padding: 20px;
  height: 55px;
  border-radius: 8px;
  border: 1px;
  background-color: #5b4b86;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  cursor: pointer;
  color: white;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  span {
    font-family: Poppins !important;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
  }
  img {
    margin-top: 5px;
  }
  margin-left: ${tablet() ? "auto" : ""};
  margin-right: ${tablet() ? "auto" : ""};
`;
