import * as React from "react";
import order from "../../assets/svg/tesst3.svg";
import medicine from "../../assets/svg/tesst1.svg";
import hospital from "../../assets/svg/tesst2.svg";
import Ticker from "../../Components/Ticker";
import isMobile, { tablet } from "../../assets/isMobile";
import {
  SummaryContent,
  SummaryImg,
  SummaryTopic,
} from "../../styles/StyledComponents/Home/Summary";
import "./Summary.css";

function Summary() {
  return (
    <div
      style={{
        // marginTop: "20px",
        width: "100%",
        backgroundColor: "#F0EAFF",
        display: "flex",
        padding: isMobile() ? "5% 15%" : "5% 15%",
        // gap: "14%",
        justifyContent: "center",
        flexWrap: isMobile() ? "wrap" : "",
        paddingLeft: tablet() ? "6%" : "15%",
        paddingRight: tablet() ? "6%" : "15%",
        flexDirection: isMobile() ? "column" : "",
        alignItems: isMobile() ? "center" : "",
      }}
      id="summary"
    >
      <SummaryContent>
        <div style={{ display: isMobile() ? "" : "flex", gap: "5px" }}>
          <SummaryImg
            src={order}
            alt="order"
            style={{
              width: "70px",
              height: "70px",
              marginBottom: "-11%",
            }}
          />
          <div style={{ width: "max-content", margin: "11% 0%" }}>
            <Ticker className="count tickers" end={500000} suffix="+" />
            <SummaryTopic>Orders delivered</SummaryTopic>
          </div>
        </div>
      </SummaryContent>
      <SummaryContent>
        <div style={{ display: isMobile() ? "" : "flex", gap: "5px" }}>
          <SummaryImg
            src={medicine}
            alt="medicine"
            style={{
              width: "70px",
              height: "70px",
              marginBottom: "-11%",
            }}
          />
          <div style={{ width: "max-content", margin: "11% 0%" }}>
            <Ticker className="count tickers" end={50000} suffix="+" />
            <SummaryTopic>Medicine brands</SummaryTopic>
          </div>
        </div>
      </SummaryContent>
      <SummaryContent>
        <div style={{ display: isMobile() ? "" : "flex", gap: "10px" }}>
          <SummaryImg
            src={hospital}
            alt="hospital"
            style={{
              width: "70px",
              height: "70px",
              marginBottom: "-11%",
            }}
          />
          <div style={{ width: "max-content", margin: "11% 0%" }}>
            <Ticker className="count tickers" end={10000} suffix="+" />
            <SummaryTopic>Hospitals</SummaryTopic>
          </div>
        </div>
      </SummaryContent>
    </div>
  );
}

export default Summary;
