import styled from "styled-components";

export const SummaryTopic = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  // color: #ffffff;
  text-align: center;
  margin-top:-20px @media (max-width: 768px) {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
  }
`;
export const SummaryContent = styled.div` 
  text-align:center;
  width:33%;
  display: flex;
  justify-content: center;
  // color:#ffffff
  }
`;
export const SummaryImg = styled.img` 
margin-bottom: -40px
}
`;
