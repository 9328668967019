import { Grid } from "@mui/material";
import React from "react";
import clip from "../../assets/svg/pic3.svg";
import pic2 from "../../assets/images/unios.png";
import pic3 from "../../assets/svg/pic2.svg";
import pic4 from "../../assets/svg/pic6.svg";
import pic5 from "../../assets/svg/pic1.svg";
import pic6 from "../../assets/svg/pic5.svg";
import pic7 from "../../assets/svg/pic4.svg";
import styled from "styled-components";
import isMobile, { tablet } from "../../assets/isMobile";
import "./Summary.css";

function Faster() {
  const Box = styled.div`
    width: max-content;
    display: flex;
    gap: 20px;
    padding: 11px;
    cursor: pointer;
    &:hover {
      border: 2px solid #f9f6ff;
      border-radius: 20px;
      background-color: #f9f6ff;
      box-shadow: 6px 6px 9px 0px #00000029;
    }
  `;

  return isMobile() ? (
    <>
      <div style={{ textAlign: "center" }} id="faster">
        <h3
          style={{
            fontFamily: "Poppins",
            fontSize: "22px",
            fontWeight: "500",
            lineHeight: "33px",
            letterSpacing: "0em",
            textAlign: "center",
            color: "#5B4B86",
          }}
        >
          KEY FEATURES
        </h3>
        <div
          style={{
            width: "100%",
            marginLeft: isMobile() ? "" : "9%",
          }}
        >
          <div
            style={{ display: "flex", justifyContent: "center", gap: "20px" }}
          >
            <Box>
              <div>
                <img
                  src={clip}
                  style={{
                    width: "61px",
                    height: "61px",
                    marginTop: "1.4%",
                  }}
                />
                <h3
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "15px",
                    fontWeight: "600",
                    lineHeight: "100%",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  Virtual Inventory of all SKUs
                </h3>
                <p
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "15px",
                    fontWeight: "400",
                    lineHeight: "100%",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  more than 50k+ SKUs
                </p>
              </div>
            </Box>
          </div>

          <div
            style={{ display: "flex", justifyContent: "center", gap: "20px" }}
          >
            <Box>
              <div>
                <img
                  src={pic3}
                  style={{
                    width: "61px",
                    height: "61px",
                    marginTop: "1.4%",
                  }}
                />
                <h3
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "15px",
                    fontWeight: "600",
                    lineHeight: "100%",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  Intelligent Product Exploration
                </h3>
                <p
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "15px",
                    fontWeight: "400",
                    lineHeight: "100%",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  5-20% additional discount
                </p>
              </div>
            </Box>
          </div>

          <div
            style={{ display: "flex", justifyContent: "center", gap: "20px" }}
          >
            <Box>
              <div>
                <img
                  src={pic7}
                  style={{
                    width: "61px",
                    height: "61px",
                    marginTop: "1.4%",
                  }}
                />
                <h3
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "15px",
                    fontWeight: "600",
                    lineHeight: "100%",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  Timely Slot Delivery
                </h3>
                <p
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "15px",
                    fontWeight: "400",
                    lineHeight: "100%",
                    letterSpacing: "0em",
                    textAlign: "center",
                    width: "90%",
                  }}
                >
                  Live tracking with digital payment
                </p>
              </div>
            </Box>
          </div>

          <div
            style={{ display: "flex", justifyContent: "center", gap: "20px" }}
          >
            <Box>
              <div>
                <img
                  src={pic4}
                  style={{
                    width: "61px",
                    height: "61px",
                    marginTop: "1.4%",
                  }}
                />
                <h3
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "15px",
                    fontWeight: "600",
                    lineHeight: "100%",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  Medicine Catalogue
                </h3>
                <p
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "15px",
                    fontWeight: "400",
                    lineHeight: "100%",
                    letterSpacing: "0em",
                    textAlign: "center",
                    width: "90%",
                  }}
                >
                  {" "}
                  1 lakh+ medicines detailed info
                </p>
              </div>
            </Box>
          </div>

          <div
            style={{ display: "flex", justifyContent: "center", gap: "20px" }}
          >
            <Box>
              <div>
                <img
                  src={pic6}
                  style={{
                    width: "61px",
                    height: "61px",
                    marginTop: "1.4%",
                  }}
                />
                <h3
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "15px",
                    fontWeight: "600",
                    lineHeight: "100%",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  Primary & Secondary Market together
                </h3>
                <p
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "15px",
                    fontWeight: "400",
                    lineHeight: "100%",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  Cheaper procurement
                </p>
              </div>
            </Box>
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", gap: "20px" }}
          >
            <Box>
              <div>
                <img
                  src={pic5}
                  style={{
                    width: "61px",
                    height: "61px",
                    marginTop: "1.4%",
                  }}
                />
                <h3
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "15px",
                    fontWeight: "600",
                    lineHeight: "100%",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  Digital Footprint
                </h3>
                <p
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "15px",
                    fontWeight: "400",
                    lineHeight: "100%",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  Grow your business digitally
                </p>
              </div>
            </Box>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div
        style={{ textAlign: "center", margin: tablet() ? "0% 5%" : "0% 15%" }}
        id="faster"
      >
        <h3
          style={{
            fontFamily: "Poppins",
            fontSize: "32px",
            fontWeight: "500",
            lineHeight: "48px",
            letterSpacing: "0em",
            textAlign: "center",
            color: "#5B4B86",
          }}
        >
          KEY FEATURES
        </h3>
        <div
          style={{
            width: "100%",
            // marginLeft: isMobile() ? "" : "9%",
          }}
        >
          <div class="main">
            <div class="circle">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <Box>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      gap: tablet() ? "20px" : "11px",
                    }}
                  >
                    <img
                      src={clip}
                      style={{
                        width: "61px",
                        height: "61px",
                        marginTop: "1.4%",
                      }}
                    />
                    <div>
                      <h3
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontWeight: "600",
                          lineHeight: "100%",
                          letterSpacing: "0em",
                          textAlign: "center",
                          color: "#5B4B86",
                        }}
                      >
                        Virtual Inventory of all SKUs
                      </h3>
                      <p
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "100%",
                          letterSpacing: "0em",
                          textAlign: "right",
                          width: tablet() ? "178px" : "",
                        }}
                      >
                        more than 50k+ SKUs
                      </p>
                    </div>
                  </div>
                </Box>
              </div>
            </div>
            <div class="circle">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: tablet() ? "20px" : "11px",
                }}
              >
                <Box>
                  <div
                    style={{
                      display: "flex",
                      gap: "11px",
                    }}
                  >
                    <img
                      src={pic3}
                      style={{
                        width: "61px",
                        height: "61px",
                        marginTop: "1.4%",
                      }}
                    />
                    <div>
                      <h3
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontWeight: "600",
                          lineHeight: "100%",
                          letterSpacing: "0em",
                          textAlign: "center",
                          color: "#5B4B86",
                        }}
                      >
                        Intelligent Product Exploration
                      </h3>
                      <p
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "100%",
                          letterSpacing: "0em",
                          textAlign: "left",
                          width: tablet() ? "178px" : "",
                        }}
                      >
                        5-20% additional discount
                      </p>
                    </div>
                  </div>
                </Box>
              </div>
            </div>
            <div class="circle">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: tablet() ? "20px" : "11px",
                }}
              >
                <Box>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      gap: "11px",
                    }}
                  >
                    <img
                      src={pic7}
                      style={{
                        width: "61px",
                        height: "61px",
                        marginTop: "1.4%",
                      }}
                    />
                    <div>
                      <h3
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontWeight: "700",
                          lineHeight: "100%",
                          letterSpacing: "0em",
                          textAlign: "right",
                          color: "#5B4B86",
                        }}
                      >
                        Timely Slot Delivery
                      </h3>
                      <p
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "100%",
                          letterSpacing: "0em",
                          textAlign: "right",
                          width: tablet() ? "178px" : "",
                        }}
                      >
                        Live tracking with digital payment
                      </p>
                    </div>
                  </div>
                </Box>
              </div>
            </div>
            <div class="circle">
              <img
                src={pic2}
                style={{
                  width: "152px",
                  // height: "51px",
                  marginTop: "7%",
                }}
              />
            </div>
            <div class="circle">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <Box>
                  <div
                    style={{
                      display: "flex",
                      gap: tablet() ? "20px" : "11px",
                    }}
                  >
                    <img
                      src={pic5}
                      style={{
                        width: "61px",
                        height: "61px",
                        marginTop: "1.4%",
                      }}
                    />
                    <div>
                      <h3
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontWeight: "600",
                          lineHeight: "100%",
                          letterSpacing: "0em",
                          textAlign: "left",
                          color: "#5B4B86",
                        }}
                      >
                        Medicine Catalogue
                      </h3>
                      <p
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "100%",
                          letterSpacing: "0em",
                          textAlign: "left",
                          width: tablet() ? "178px" : "",
                        }}
                      >
                        1 lakh+ medicines detailed info
                      </p>
                    </div>
                  </div>
                </Box>
              </div>
            </div>
            <div class="circle">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <Box>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      gap: tablet() ? "20px" : "11px",
                    }}
                  >
                    <img
                      src={pic6}
                      style={{
                        width: "61px",
                        height: "61px",
                        marginTop: "1.4%",
                      }}
                    />
                    <div>
                      <h3
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontWeight: "600",
                          lineHeight: "100%",
                          letterSpacing: "0em",
                          textAlign: "right",
                          color: "#5B4B86",
                        }}
                      >
                        Primary & Secondary Market together
                      </h3>
                      <p
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "100%",
                          letterSpacing: "0em",
                          textAlign: "right",
                          width: tablet() ? "178px" : "",
                        }}
                      >
                        Cheaper procurement
                      </p>
                    </div>
                  </div>
                </Box>
              </div>
            </div>
            <div class="circle">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <Box>
                  <div
                    style={{
                      display: "flex",
                      gap: tablet() ? "20px" : "11px",
                    }}
                  >
                    <img
                      src={pic4}
                      style={{
                        width: "61px",
                        height: "61px",
                        marginTop: "1.4%",
                      }}
                    />
                    <div>
                      <h3
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontWeight: "600",
                          lineHeight: "100%",
                          letterSpacing: "0em",
                          textAlign: "left",
                          color: "#5B4B86",
                        }}
                      >
                        Digital Footprint
                      </h3>
                      <p
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "100%",
                          letterSpacing: "0em",
                          textAlign: "left",
                        }}
                      >
                        Grow your business digitally
                      </p>
                    </div>
                  </div>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Faster;
