import React from "react";
import howitwork from "../../assets/images/howitwork.png";
import howitworkV2 from "../../assets/images/howitworksv2.png";
import styled from "styled-components";
import isMobile, { tablet } from "../../assets/isMobile";

function HowitsWork() {
  const ButtonStyle = styled.button`
    color: #5b4b86;
    background-color: #fff;
    border: 1px solid #5b4b86;
    width: 177px;
    height: 55px;
    borderadius: 8px;
    cursor: pointer;
    border-radius: 8px;
    font-family: "Poppins";
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    margin-bottom: 21px;
    &:hover {
      color: #fff;
      background-color: #5b4b86;
      border: 1px solid #fff;
    }
  `;
  return (
    <div
      style={{
        textAlign: "center",
        // padding: tablet() ? "" : "0% 15%",
        margin: "0% 15%",
      }}
      id="howitswork"
    >
      <h4
        style={{
          fontFamily: "Poppins",
          fontSize: isMobile() ? "22px" : "32px",
          fontWeight: "500",
          lineHeight: isMobile() ? "33px" : "48px",
          letterSpacing: "0em",
          textAlign: "center",
          color: "#5B4B86",
        }}
      >
        How it works
      </h4>
      <img
        src={isMobile() ? howitworkV2 : howitwork}
        style={{ marginLeft: isMobile() ? "-5%" : "" }}
      />
      <h4
        style={{
          fontFamily: "Poppins",
          fontSize: isMobile() ? "16px" : "24px",
          fontWeight: "500",
          lineHeight: isMobile() ? "24px" : "36px",
          letterSpacing: "0em",
          textAlign: "center",
          color: "#5B4B86",
          width: "86%",
          marginLeft: "7%",
        }}
      >
        Managed marketplace
      </h4>
      <p
        style={{
          fontFamily: "Poppins",
          fontSize: isMobile() ? "16px" : "18px",
          fontWeight: "400",
          lineHeight: isMobile() ? "24px" : "27px",
          letterSpacing: "0em",
          textAlign: "center",
          // padding: "0% 12%",
        }}
      >
        Our commitment to excellence and customer satisfaction has earned us a
        reputation for reliability and efficiency. With PPDL, you can have peace
        of mind knowing that life-saving medications reach their intended
        destinations promptly and securely.
      </p>
      <ButtonStyle>Learn more</ButtonStyle>
    </div>
  );
}

export default HowitsWork;
