import React from "react";
import isMobile, { tablet } from "../../assets/isMobile";
import Topics from "../../assets/svg/topic.svg";
import {
  Container,
  Content,
  SubContent,
} from "../../styles/StyledComponents/Home/Topic";
import topic_h from "../../assets/topic_h.png";

function Topic() {
  const handleClick = () => {
    const aboutus = document.getElementById("aboutus");
    if (aboutus) {
      aboutus.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div id="topic">
      <Container
        style={{
          marginTop: isMobile() ? "15%" : "0%",
          marginBottom: "0%",
          height: isMobile() ? "100%" : "100%",
          // marginLeft: "-4%",
          width: "100%",
          // paddingTop: "5%",
          paddingLeft: "15%",
          paddingRight: "15%",
          textAlign: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            // paddingLeft: "4%",
            // backgroundColor: "#F9F7FF",
            flexWrap: isMobile() ? "wrap-reverse" : "",
          }}
        >
          <div
            style={{
              width: tablet() ? (isMobile() ? "100%" : "56%") : "50%",
              // marginTop: "7%",
              paddingTop: "12%",

              paddingBottom: "11%",

              textAlign: isMobile() ? "center" : "left",
            }}
          >
            <Content>Streamline your pharmacy business with UNIOS</Content>
            <SubContent>
              India's Comprehensive B2B Platform for Pharma & Healthcare
              Products
            </SubContent>
            <img
              src={topic_h}
              style={{
                // width: "31%",
                // height: "79%",
                cursor: "pointer",
                marginTop: "5%",
                marginBottom: "-5%",
              }}
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.unios.user&hl=en-IN",
                  "_blank"
                )
              }
            />
          </div>
          <div
            style={{
              width: isMobile() ? "" : tablet() ? "44%" : "50%",
            }}
          >
            {!isMobile() && (
              <div
                style={{
                  marginLeft: "auto",
                  // marginRight: "5%",
                  marginTop: tablet() ? "30%" : "15%",
                  width: tablet() ? "100%" : "100%",
                  height: tablet() ? "83%" : "",
                }}
              >
                <img
                  src={isMobile() ? Topics : Topics}
                  style={{
                    width: isMobile() ? "90%" : "100%",
                    // margin: "5%",
                    height: "100%",
                  }}
                  alt="icon"
                />
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Topic;
