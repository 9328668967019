import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Nopage from '../components/Nopage';
import Footer from "../Components/Footer";
import ResponsiveAppbar from "../Components/ResponsiveAppBar";
import Home from "../Pages/Home/Home";

function Approuter() {
  const handleClick = (value) => {
    if (value == 0) {
      const aboutus = document.getElementById("topic");
      if (aboutus) {
        aboutus.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (value == 1) {
      const aboutus = document.getElementById("aboutus");
      if (aboutus) {
        aboutus.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (value == 2) {
      const aboutus = document.getElementById("howitswork");
      if (aboutus) {
        aboutus.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (value == 3) {
      const aboutus = document.getElementById("contactus");
      if (aboutus) {
        aboutus.scrollIntoView({ behavior: "smooth" });
      }
    }
  };
  return (
    <div style={{ marginTop: "1.5%", minWidth: `${window.innerWidth - 65}px` }}>
      <ResponsiveAppbar
        onClickValue={(value) => {
          handleClick(value);
        }}
      />
      <Home />
      <Footer />
    </div>
  );
}

export default Approuter;
