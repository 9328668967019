import { Button } from "@mui/material";
import styled from "styled-components";

export const MenuItems = styled(Button)`
  // width: 93px !important;
  height: 48px !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  text-align: center;
  color: #040e0f !important;
  text-transform: none !important;
  margin-right: 11px !important;
  color: #5b4b86 !important;
  &:hover {
    color: #422e75 !important;
  }
  ${(props) =>
    props.active
      ? "border-bottom: 5px solid #422E75 !important;border-radius: 0px !important;"
      : ""}
`;

export const MenuItemsContact = styled(Button)`
  font-family: "Mukta" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 100% !important;
  text-align: center;
  text-transform: none !important;
  margin-left: 39px !important;
  background: linear-gradient(to right, blue 50%, white 50%) !important;
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.5s ease-out !important;
  &:hover {
    background-position: left bottom;
  }
`;
