import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
/* eslint-disable */
import { MenuItems } from "../../assets/styles/NavbarStyles";
import "./index.css";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import Submenu from "./Submenu";
import topic_h from "../../assets/topic_h.png";
import styled from "styled-components";
import arrow from "../../assets/svg/arrow.svg";
import { StyleButton } from "../../styles/StyledComponents/Footer";
import { tablet } from "../../assets/isMobile";

const navItems = ["Home", "About Us", "How it Works", "Contact"];

function DrawerAppBar(props) {
  const { onClickValue } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        MUI
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex", zIndex: "1600", marginBottom: "3.4%" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{ bgcolor: "#fff", color: "#000", zIndex: "1600" }}
        elevation={0}
      >
        <div
          style={{
            display: "flex",
            paddingLeft: "15%",
            paddingRight: "15%",
            paddingTop: "11px",
          }}
        >
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: "block", sm: "block" } }}
          >
            <img
              src="./Images/logo.png"
              style={{
                width: "137px",
                cursor: "pointer",
                // marginLeft: "12px",
              }}
              onClick={() => {
                navigate("/");
              }}
              alt="icon"
            />
          </Typography>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              mr: 2,
              display: tablet()
                ? "block"
                : { lg: "none", sm: "none", xs: "block" },
            }}
            style={{
              marginLeft: "auto",
              marginRight: "0px",
            }}
          >
            <Submenu />
          </IconButton>
          <Box
            sx={{
              display: tablet() ? "none" : { xs: "none", sm: "flex" },
            }}
          >
            {navItems.map((item, index) => (
              <MenuItems
                key={item}
                onClick={() => {
                  onClickValue(index);
                }}
                style={{ backgroundColor: "transparent" }}
              >
                {item}
              </MenuItems>
            ))}
            <StyleButton
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.unios.user&hl=en-IN",
                  "_blank"
                )
              }
            >
              <img src={arrow} />
              <span>Download the app</span>
            </StyleButton>
          </Box>
        </div>
      </AppBar>
      <Box component="nav" style={{ display: "flex !important" }}></Box>
    </Box>
  );
}

DrawerAppBar.propTypes = {
  window: PropTypes.func,
};

export default DrawerAppBar;
