import React from "react";
import isMobile, { tablet } from "../../assets/isMobile";

function Aboutus() {
  return (
    <div
      id="aboutus"
      style={{
        textAlign: "center",
        width: "100%",
        // height: "435px",
        backgroundColor: "#F9F6FF",
        paddingTop: "3.3%",
        paddingBottom: "6.56%",
      }}
    >
      <h4
        style={{
          fontFamily: "Poppins",
          fontSize: isMobile() ? "22px" : "32px",
          fontWeight: "500",
          lineHeight: isMobile() ? "33px" : "48px",
          letterSpacing: "0em",
          textAlign: "center",
          color: "#5B4B86",
          margin: isMobile() ? "3% 3%" : "",
        }}
      >
        About us
      </h4>
      <div
        style={{
          width: "100%",
          fontFamily: "Poppins",
          fontSize: isMobile() ? "14px" : "18px",
          fontWeight: "400",
          lineHeight: "27px",
          letterSpacing: "0em",
          textAlign: "center",
          color: "#545454",
          padding: "0% 15%",
        }}
      >
        India's Comprehensive B2B Platform for Pharma & Healthcare Products. Our
        commitment to excellence and customer satisfaction has earned us a
        reputation for reliability and efficiency. With PPDL, you can have peace
        of mind knowing that life-saving medications reach their intended
        destinations promptly and securely. Our commitment to excellence and
        customer satisfaction has earned us a reputation for reliability and
        efficiency. With PPDL, you can have peace of mind knowing that
        life-saving medications reach their intended destinations promptly and
        securely.
      </div>
    </div>
  );
}

export default Aboutus;
