import { Grid } from "@mui/material";
import React from "react";
import phone from "../../assets/phone.png";
import topic_h from "../../assets/topic_h.png";
import styled from "styled-components";
import isMobile, { tablet } from "../../assets/isMobile";
import arrow from "../../assets/svg/arrow.svg";
import { StyleButton } from "../../styles/StyledComponents/Footer";
function Contact() {
  const Label = styled.label`
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #422e75;
  `;
  const Input = styled.input`
    ${tablet() ? "width: 100%;" : "width: 100%;"}
    height: 50px;
    top: 3340px;
    left: 799px;
    border-radius: 4px;
    border: 1px solid #5b4b86;
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.4568345248699188px;
    text-align: left;
    margin-bottom: 20px;
    background-color: ${tablet() ? "#FFF;" : "#f9f6ff;"}
    padding: 15px;
  `;
  const InputArea = styled.textarea`
    ${tablet() ? "width: 100%;" : "width: 100%;"}
    height: 78px;
    top: 3624px;
    left: 800px;
    border-radius: 4px;
    border: 1px solid;
    color: #5b4b86;
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.4568345248699188px;
    text-align: left;
    margin-bottom: 0%;
    background-color: ${tablet() ? "#FFF;" : "#f9f6ff;"}
    padding: 15px;
  `;
  const StyleButtons = styled.button`
    width: 180px;
    height: 55px;
    border-radius: 8px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    background-color: #422e75;
    color: #fff;
    margin-top: 7.7%;
    cursor: pointer;
    margin-left: ${isMobile() ? "" : tablet() ? " " : ""};
    margin-bottom: 20px;
    @media (max-width: 768px) {
      font-family: Poppins;
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: center;
    }
  `;
  return (
    <>
      <div
        style={{ backgroundColor: "#F9F6FF", padding: "3% 15%" }}
        id="contactus"
      >
        <Grid container>
          <Grid
            item
            xs={12}
            sm={tablet() ? 12 : 6}
            style={{
              textAlign: tablet() ? "center" : "left",
              paddingTop: "4%",
            }}
          >
            <img
              src={phone}
              style={{
                width: tablet()
                  ? isMobile()
                    ? "70%"
                    : "34%"
                  : "156.982666015625px",
                //   height: "100%",
              }}
            />
            <h4
              style={{
                fontFamily: "Poppins",
                fontSize: "24px",
                fontWeight: "500",
                lineHeight: "36px",
                letterSpacing: "0em",
                textAlign: tablet() ? "center" : "left",
                color: "#5B4B86",
                cursor: "pointer",
                margin: "15px 0px",
              }}
            >
              Download the app now
            </h4>
            <p
              style={{
                fontFamily: "Poppins",
                fontSize: isMobile() ? "16.1px" : "18px",
                fontWeight: "400",
                lineHeight: isMobile() ? "24px" : "27px",
                letterSpacing: "0em",
                textAlign: tablet() ? "center" : "left",
                color: "#545454",
                width: "97%",
              }}
            >
              Our commitment to excellence and customer satisfaction has earned
              us a reputation for reliability and efficiency. With PPDL, you can
              have peace of mind knowing that life-saving medications reach
              their intended destinations promptly and securely.
            </p>
            <StyleButton
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.unios.user&hl=en-IN",
                  "_blank"
                )
              }
            >
              <img src={arrow} />
              <span>Download the app</span>
            </StyleButton>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              padding: !tablet() ? "0% 0% 4% 9.33%" : "",
              // paddingTop: "10%",
              display: tablet() ? "none" : "",
            }}
          >

            <h4
              style={{
                fontFamily: "Poppins",
                fontSize: isMobile() ? "22px" : "32px",
                fontWeight: "500",
                lineHeight: isMobile() ? "33px" : "48px",
                letterSpacing: "0em",
                textAlign: "left",
                color: "#5B4B86",
                textTransform: "Uppercase",
                margin: "0",
              }}
            >
              Contact us
            </h4>
            <form>
              <Label>Name</Label>
              <br />
              <Input placeholder="Please enter your name" type="text" />
              <br />
              <Label>Phone</Label>
              <br />
              <Input
                placeholder="Please enter your phone number"
                type="number"
              />
              <br />
              <Label>Email*</Label>
              <br />
              <Input
                placeholder="Please enter your email address"
                type="text"
              />
              <br />
              <Label>Message*</Label>
              <br />
              <InputArea
                placeholder="Please type your message here"
                type="text"
              />
              <div
                style={{
                  display: "flex",
                }}
              >
                <StyleButton style={{ width: "180px" }}>Submit</StyleButton>
              </div>
            </form>
          </Grid>
        </Grid>
      </div>
      {tablet() && (
        <div
          style={{
            backgroundColor: "white",
            margin: isMobile() ? "7%" : "",
            width: isMobile() ? "" : "50%",
            margin: isMobile() ? "5%" : "5% 5% 5% 25%",
            marginBottom: isMobile() ? "" : "6%",
          }}
        >
          <h4
            style={{
              fontFamily: "Poppins",
              fontSize: "22px",
              fontWeight: "500",
              lineHeight: "33px",
              letterSpacing: "0em",
              textAlign: "center",
              color: "#5B4B86",
              textTransform: "Uppercase",
              margin: "0",
            }}
          >
            Contact us
          </h4>
          <form>
            <Label>Name</Label>
            <br />
            <Input placeholder="Please enter your name" type="text" />
            <br />
            <Label>Phone</Label>
            <br />
            <Input placeholder="Please enter your phone number" type="number" />
            <br />
            <Label>Email*</Label>
            <br />
            <Input placeholder="Please enter your email address" type="text" />
            <br />
            <Label>Message*</Label>
            <br />
            <InputArea
              placeholder="Please type your message here"
              type="text"
            />
            <div
              style={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <StyleButtons>Submit</StyleButtons>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default Contact;
