import React from "react";
import Topic from "./Topic";
import Faster from "./Faster1";
import Summary from "./Summary";
import Aboutus from "./Aboutus";
import HowitsEco from "./HowitsEco";
import HowitsWork from "./HowitsWork";
import Contact from "./Contact";

function Home() {
  return (
    <>
      <Topic />
      <Summary />
      <Aboutus />
      <Faster />
      <HowitsEco />
      <HowitsWork />
      <Contact />
    </>
  );
}

export default Home;
